const defineNetworkName = (chainId: number | null) : string => {
  switch (chainId) {
    case 137: return 'polygon'
    case 11155111: return 'sepolia'
    case 8453: return 'base'
    case 84531: return 'baseGoerli'
    case 100: return 'gnosis'
    case 43114: return 'avalanche'
    case 42161: return 'arbitrum'
    case 10: return 'optimism'
    default: return 'polygon'
  }
}

export default defineNetworkName