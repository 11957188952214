import { FC,  } from 'react'
import { Redirect } from 'react-router-dom'
import PageConnector from '../page-connector'
import { RootState, IAppDispatch } from 'data/store'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { defineTokenAddressBySymbol } from 'helpers'
import * as userAsyncActions from 'data/store/reducers/user/async-actions'
import {
  useConnection,
  useEthersSigner
} from 'hooks'

const mapDispatcherToProps = (dispatch: IAppDispatch) => {
  return {
    initialize: (
      connector: any,
      signer: any,
      userChainId: number,
      chainIdToBeConnected?: string,
      userAddress?: string | null
    ) => dispatch(userAsyncActions.initialize(
      connector,
      signer,
      userChainId,
      chainIdToBeConnected,
      userAddress
    )),
    reinitialize: (
      address: string,
      chainId: number,
      connector?: any,
      signer?: any,
      callback?: () => void,
    ) => dispatch(userAsyncActions.updateUserData(
      address,
      chainId,
      connector,
      signer,
      callback
    )),
  }
}

const defineRedirectUrl = (
  chainId: number | null
) => {
  if (!chainId) {
    chainId = 8453 
  }
  const tokenAddress = defineTokenAddressBySymbol('usdc', chainId)
  return <Redirect to={`/create/${chainId}/${tokenAddress}?amount=0`} />
}

// @ts-ignore
type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>

const mapStateToProps = ({
  user: { chainId, initialized },
}: RootState) => ({
  chainId, initialized
})

const HomePage: FC<ReduxType> = ({
  chainId,
  initialized,
  initialize,
  reinitialize
}) => {

  const signer = useEthersSigner()


  useConnection(
    initialized,
    initialize,
    reinitialize,
    signer
  )

  const redirect = defineRedirectUrl(chainId)
  return <PageConnector>
    {redirect}
  </PageConnector>

}

export default connect(mapStateToProps, mapDispatcherToProps)(HomePage)