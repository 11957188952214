import { FC } from 'react'
import {
  Container,
  ScreenButton,
  TitleComponent,
  Subtitle,
  Image
} from './styled-components'
import { defineRealNetworkName } from 'helpers'
import { RootState } from 'data/store'
import { connect } from 'react-redux'
import { switchNetwork } from 'data/store/reducers/user/async-actions'
import Wrongetwork from 'images/network.png'

const mapStateToProps = ({
  user: { userProvider },
  token: { tokenType },
  link: { chainId }
}: RootState) => ({
  tokenType,
  chainId,
  userProvider
})

type ReduxType = ReturnType<typeof mapStateToProps>

const ChangeNetwork: FC<ReduxType> = ({
  chainId,
  userProvider,
  tokenType
}) => {
  const networkName = defineRealNetworkName(chainId)
  return <Container>
    <Image src={Wrongetwork} alt='Wrong network' />
    <TitleComponent>Switch network</TitleComponent>
    <Subtitle>To claim {tokenType === 'ERC20' || tokenType === 'NATIVE' ? 'tokens' : 'an NFT'} you need to switch your wallet to {networkName} network</Subtitle>
    <ScreenButton onClick={async () => {
      if (chainId) {
        switchNetwork(chainId, () => {})
      } else {
        alert('No chain provided')
      }
    }}>
      Switch network
    </ScreenButton>
  </Container>
}

export default connect(mapStateToProps)(ChangeNetwork)
