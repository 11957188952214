import { TWalletName } from 'types'

const _withoutProtocol = (url: string) => url.replace(/(^\w+:|^)\/\//, '')

type TWalletDeeplink = (url: string) => string | null

type TWallet = {
  id: string,
  name: string,
  chains: number[],
  walletURL?: string | null,
  dappStoreUrl?: string | null,
  walletURLIos?: string | null,
  mobile: {
    android: {
      support: boolean,
      deepLink?: TWalletDeeplink
    },
    ios: {
      support: boolean,
      deepLink?: TWalletDeeplink
    }
  }
}

type TWallets = Record<string, TWallet>

const wallets: TWallets = {
  metamask: {
    id: 'metamask',
    name: 'MetaMask',
    chains: [1, 137, 11155111, 8453, 84531, 100, 10, 42161, 43114],
    mobile: {
      android: {
        support: true,
        deepLink: (url: string) => `https://metamask.app.link/dapp/${_withoutProtocol(url)}`
      },
      ios: {
        support: true,
        deepLink: (url: string) => `https://metamask.app.link/dapp/${_withoutProtocol(url)}`
      }
    },
  },
  coinbase_wallet: {
    id: 'coinbase_wallet',
    name: 'Coinbase Wallet',
    walletURL: 'https://www.toshi.org',
    dappStoreUrl: null,
    mobile: {
      android: {
        support: true,
        deepLink: (url: string) => `https://go.cb-w.com/dapp?cb_url=${encodeURIComponent(url)}`
      },
      ios: {
        support: true,
        deepLink: (url: string) => `https://go.cb-w.com/dapp?cb_url=${encodeURIComponent(url)}`
      }
    },
    chains: [1, 137, 11155111, 8453, 84531, 100, 10, 42161, 43114]
  },
  walletconnect: {
    id: 'walletconnect',
    name: 'WalletConnect',
    chains: [1, 137, 11155111, 8453, 84531, 100, 10, 42161, 43114],
    mobile: {
      android: {
        support: false
      },
      ios: {
        support: false,
      }
    },
  }
}

export default wallets
