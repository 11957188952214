import defineNetworkName from './define-network-name'
const {
  REACT_APP_JSON_RPC_POLYGON,
  REACT_APP_JSON_RPC_GOERLI,
  REACT_APP_JSON_RPC_SEPOLIA,
  REACT_APP_JSON_RPC_BASE,
  REACT_APP_JSON_RPC_GNOSIS,
  REACT_APP_JSON_RPC_BASEGOERLI,
  REACT_APP_JSON_RPC_OPTIMISM,
  REACT_APP_JSON_RPC_ARBITRUM,
  REACT_APP_JSON_RPC_AVALANCHE
} = process.env

const defineJSONRpcUrl = ({ chainId, infuraPk } : { chainId: number, infuraPk: string }) => {
  const networkName = defineNetworkName(chainId)

  if (networkName === 'polygon') {
    return REACT_APP_JSON_RPC_POLYGON
  } else if (networkName === 'sepolia') {
    return REACT_APP_JSON_RPC_SEPOLIA
  } else if (networkName === 'goerli') {
    return REACT_APP_JSON_RPC_GOERLI
  } else if (networkName === 'base') {
    return REACT_APP_JSON_RPC_BASE
  } else if (networkName === 'baseGoerli') {
    return REACT_APP_JSON_RPC_BASEGOERLI
  } else if (networkName === 'gnosis') {
    return REACT_APP_JSON_RPC_GNOSIS
  } else if (networkName === 'avalanche') {
    return REACT_APP_JSON_RPC_AVALANCHE
  } else if (networkName === 'optimism') {
    return REACT_APP_JSON_RPC_OPTIMISM
  } else if (networkName === 'arbitrum') {
    return REACT_APP_JSON_RPC_ARBITRUM
  }
  
  return `https://${networkName}.infura.io/v3/${infuraPk}`
}

export default defineJSONRpcUrl