import { FC, useState } from 'react'
import {
  Container,
  LinkButton,
  TextComponent
} from './styled-components'
import { RootState, IAppDispatch } from 'data/store'
import { connect } from 'react-redux'
import { useWeb3Modal } from '@web3modal/wagmi/react'
import MetamaskIcon from 'images/metamask-wallet.png'
import CoinabseWalletIcon from 'images/coinbase-wallet.png'
import WalletConnectIcon from 'images/walletconnect-wallet.png'
import { useConnect, Connector } from 'wagmi'
import { TClaimLinkStep, TWalletName, TWalletOption, TTokenType, TCreateLinkStep } from 'types'
import { AdditionalNoteComponent, OverlayScreen } from 'linkdrop-ui'
import * as linkAsyncActions from 'data/store/reducers/link/async-actions'
import { Dispatch } from 'redux'
import { useQuery } from 'hooks'
import { LinkActions } from 'data/store/reducers/link/types'
import { PopupWalletListContents, PopupWhatIsWalletContents } from 'components/pages/common'
import {
  defineSystem,
  sortWallets,
  getWalletOption,
  getInjectedWalletOption
} from 'helpers'
import BrowserWalletIcon from 'images/browser-wallet.png'
import TProps from './types'
import { ButtonList } from 'components/common'
import { plausibleApi } from 'data/api'
import { useTheme } from 'styled-components'

const mapStateToProps = ({
  token: {
    name,
    image
  },
  link: { 
    chainId,
    isManual,
    loading: linkLoading
  },
  token: {
    tokenType
  },
  createLink: {
    loading
  },
  user: {
    walletApp,
    loading: userLoading
  }
}: RootState) => ({
  name,
  image,
  tokenType,
  walletApp,
  chainId,
  isManual,
  loading,
  userLoading,
  linkLoading
})

const mapDispatcherToProps = (dispatch: IAppDispatch & Dispatch<LinkActions>) => {
  return {
    deeplinkRedirect: (
      deeplink: string,
      walletId: TWalletName,
      redirectCallback: () => void
    ) => dispatch(linkAsyncActions.deeplinkRedirect(
      deeplink,
      walletId,
      redirectCallback
    ))
  }
}

// @ts-ignore
type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps> & TProps

const isOptionVisible = (
  option: TWalletOption | undefined,
  additionalCondition?: boolean
) => {
  if (additionalCondition !== undefined && !additionalCondition) {
    return undefined
  }
  if (!option) { return undefined }
  return option
}

const defineOptionsList = (
  type: TTokenType | null,
  setStep: (step: TClaimLinkStep & TCreateLinkStep) => void,
  web3Modal: any,
  connect: any,
  connectors: readonly Connector[],
  wallet: TWalletName | null,
  deeplinkRedirect: (
    deeplink: string,
    walletId: TWalletName
  ) => Promise<void>,
  isManual: boolean,
  chainId: number
) => {

  const system = defineSystem()

  const walletConnectOption = {
    title: 'WalletConnect',
    onClick: () => {
      plausibleApi.invokeEvent({
        eventName: 'wallet_connection',
        data: {
          wallet: 'walletconnect'
        }
      })
      web3Modal.open()
    },
    icon: WalletConnectIcon,
    recommended: wallet === 'walletconnect'
  }

  const injected = connectors.find(connector => connector.id === "injected")
  const injectedOption = getInjectedWalletOption(
    wallet,
    system,
    () => {
      setStep('download_await')
    },
    connect,
    BrowserWalletIcon,
    injected
  )

  if (system === 'desktop') {
    const coinbaseConnector = connectors.find(connector => connector.id === "coinbaseWalletSDK")
    const coinbaseOption = {
      title: 'Coinbase Wallet',
      onClick: () => {
        if (!coinbaseConnector) {
          return alert('Cannot connect to Coinbase connector')
        }
        plausibleApi.invokeEvent({
          eventName: 'wallet_connection',
          data: {
            wallet: 'coinbase_wallet'
          }
        })
        connect({ connector: coinbaseConnector })
      },
      icon: CoinabseWalletIcon,
      recommended: wallet === 'coinbase_wallet'
    }

    const wallets = [
      isOptionVisible(injectedOption),
      // isOptionVisible(crossmintOption, type !== 'ERC20'),
      isOptionVisible(coinbaseOption),
      isOptionVisible(walletConnectOption),
      // isOptionVisible(ledgerOption),
    ]

    return sortWallets(wallets) 
  }

  const injectedOptionIsBrave = injected && injected.name === 'Brave Wallet'

  const metamaskOption = (injectedOption && !injectedOptionIsBrave) ? undefined : getWalletOption(
    'metamask',
    'Metamask',
    system,
    window.location.href, 
    chainId,
    MetamaskIcon,
    deeplinkRedirect,
    wallet
  )

  const coinbaseOption = (injectedOption && !injectedOptionIsBrave) ? undefined : getWalletOption(
    'coinbase_wallet',
    'Coinbase Wallet',
    system,
    window.location.href, 
    chainId,
    CoinabseWalletIcon,
    deeplinkRedirect,
    wallet
  )

  const wallets = [
    isOptionVisible(coinbaseOption),
    isOptionVisible(injectedOption),
    isOptionVisible(metamaskOption),
    isOptionVisible(walletConnectOption),
  ]

  return sortWallets(wallets)
}

const WalletsList: FC<ReduxType> = ({
  setStep,
  recommendedWalletApp,
  chainId,
  isManual,
  deeplinkRedirect,
  enableENS,
  enableZerion,
  tokenType,
  loading,
  linkLoading,
  userLoading
}) => {
  const web3Modal = useWeb3Modal()
  const { connect, connectors } = useConnect()

  const [ showPopup, setShowPopup ] = useState<boolean>(false)
  const system = defineSystem()
  const injected = connectors.find(connector => connector.id === "injected")
  const queryParams = useQuery()
  const theme = useTheme()

  const options = defineOptionsList(
    tokenType,
    setStep,
    web3Modal,
    connect,
    connectors,
    recommendedWalletApp,
    (deeplink: string, walletId: TWalletName) => deeplinkRedirect(deeplink, walletId, () => setStep('wallet_redirect_await')),
    isManual,
    chainId as number
  )

  return <Container>
    <TextComponent dangerouslySetInnerHTML={{__html: 'Choose wallet you use and you will be redirected to it' }} />
    <ButtonList items={options} loading={loading || linkLoading || userLoading} />
    {system === 'desktop' && !injected && <LinkButton onClick={() => {
      setShowPopup(true)
    }}>What is browser wallet?</LinkButton>}
    {system !== 'desktop' && <AdditionalNoteComponent
      text='Don’t know what to choose?'
      position='bottom'
      onClick={() => {
        setShowPopup(true)
      }}
    />}
    {showPopup && <OverlayScreen
      // @ts-ignore
      headerLogo={theme.logo as string}
      title={system === 'desktop' ? 'What is a Wallet?' : 'Connecting your wallet'}
      onCloseAction={() => { setShowPopup(false) }}
      mainAction={() => { setShowPopup(false) }}
    >
      {system === 'desktop' ? <PopupWhatIsWalletContents /> : <PopupWalletListContents />}
    </OverlayScreen>}
  </Container>
}

// @ts-ignore
export default connect(mapStateToProps, mapDispatcherToProps)(WalletsList)