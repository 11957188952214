import BaseNetworkIcon from 'images/base-network-icon.png'
import PolygonNetworkIcon from 'images/polygon-network-icon.png'
import GnosisNetworkIcon from 'images/gnosis-network-icon.png'
import ArbitrumNetworkIcon from 'images/arbitrum-network-icon.png'
import OptimismNetworkIcon from 'images/optimism-network-icon.png'
import AvalancheNetworkIcon from 'images/avalanche-network-icon.png'
import SepoliaNetworkIcon from 'images/mumbai-network-icon.png'

const defineNetworkIcon = (chainId: number) => {
  switch (chainId) {
    case 10:
      return OptimismNetworkIcon
    case 42161:
      return ArbitrumNetworkIcon
    case 43114:
      return AvalancheNetworkIcon
    case 100:
      return GnosisNetworkIcon
    case 137:
      return PolygonNetworkIcon
    case 11155111:
    // need logo
      return SepoliaNetworkIcon
    default:
      return BaseNetworkIcon
  }
}

export default defineNetworkIcon
