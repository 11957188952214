import {
  FC,
  useEffect,
  useState
} from 'react'
import { RootState, IAppDispatch } from 'data/store'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { CreateLinkActions } from 'data/store/reducers/create-link/types'
import { HistoryActions } from 'data/store/reducers/history/types'
import * as createLinkActions from 'data/store/reducers/create-link/actions'
import * as historyActions from 'data/store/reducers/history/actions'
import * as historyAsyncActions from 'data/store/reducers/history/async-actions'
import { TCreateLinkStep, TWalletName } from 'types'
import { useHistory } from 'react-router-dom'
import { ButtonList } from 'components/common'
import {
  Container,
  ButtonContainer,
  ButtonStyled
} from '../styled-components'
import { formatDate, сreateQueryString } from 'helpers'
import { ethers } from 'ethers'
import { useQuery } from 'hooks'
import { Loader } from 'components/common'

const mapStateToProps = ({
  createLink: { loading },
  history: {
    items: historyItems,
    total,
    loading: historyLoading
  }
}: RootState) => ({
  loading,
  historyLoading,
  historyItems,
  total
})

const mapDispatcherToProps = (dispatch: Dispatch<CreateLinkActions> &
                                        Dispatch<HistoryActions> &
                                        IAppDispatch) => {
  return {
    setStep: (step: TCreateLinkStep) => dispatch(createLinkActions.setStep(step)),
    getHistory: (
      page: number,
      limit: number
    ) => dispatch(historyAsyncActions.getHistory(
      page,
      limit
    )),
    setInitialHistory: () => dispatch(historyActions.setItems([])),
  }
}

type ReduxType = ReturnType<typeof mapDispatcherToProps> & ReturnType<typeof mapStateToProps>

const HistoryScreen: FC<ReduxType> = ({
  getHistory,
  historyItems,
  historyLoading,
  total,
  setInitialHistory
}) => {
  const query = useQuery()
  const [ page, setPage ] = useState(0)
  const limit = 5
  useEffect(() => {
    setInitialHistory()
  }, [])

  useEffect(() => {
    getHistory(
      page,
      limit
    )
  }, [page])

  const history = useHistory()

  const buttonListItems = historyItems.map(item => {
    let value = String(ethers.utils.formatUnits(item.amount as string, item.decimals as number))
    if (item.status === 'refunded') {
      value = `+${value}`
    } else {
      value = `-${value}`
    }
    return {
      title: item.symbol as string,
      description: formatDate(item.createdAt as string),
      onClick: () => {
        const params = сreateQueryString({
          amount: '0',
          w: (query.get('w') as TWalletName) || 'metamask',
          theme: query.get('theme') || 'light',
        })
        history.push(`/links/${item.chainId}/${item.transferId}?${params}`)
      },
      icon: item.image as string,
      status: item.status,
      value
    }
  })

  return <Container>
    {historyLoading && <Loader />}
    {!historyLoading && <ButtonList
      items={buttonListItems}
      notFoundTitle='No history yet'
    />}

    {total > 5 && <ButtonContainer>
      <ButtonStyled
        appearance='action'
        disabled={((page + 1) * limit) >= total}
        onClick={() => {
          setPage(page + 1)
        }}
      >
        Load more
      </ButtonStyled>
    </ButtonContainer>}
    
  </Container>
}

export default connect(mapStateToProps, mapDispatcherToProps)(HistoryScreen)