import { FC, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { RootState, IAppDispatch } from 'data/store'
import { connect } from 'react-redux'
import * as createLinkAsyncActions from 'data/store/reducers/create-link/async-actions'
import { PageConnector } from '..'
import { TLinkStatus, TWalletName } from 'types'
import { StatusContainer } from 'components/pages/common'
import { Container } from './styled-components'
import {
  PageHeader
} from 'components/pages/common'
import { сreateQueryString } from 'helpers'
import { ethers } from 'ethers'
import { useQuery } from 'hooks'

const mapStateToProps = ({
  user: { address, provider, chainId, initialized },
  createLink: {
    step,
    loading
  },
  link: {
    linkStatus,
    lastTxHash,
    createdAt,
    escrowAddress
  },
  token: {
    address: tokenAddress,
    amount,
    name,
    decimals
  }
}: RootState) => ({
  address,
  step,
  provider,
  chainId,
  initialized,
  linkStatus,
  lastTxHash,
  createdAt,
  tokenAddress,
  escrowAddress,
  amount,
  name,
  decimals,
  loading
})

const mapDispatcherToProps = (dispatch: IAppDispatch) => {
  return {
    getLinkStatus: (
      transferId: string,
      callback: (location: string) => void
    ) => dispatch(createLinkAsyncActions.getLinkStatus(
      transferId,
      callback
    )),
  }
}

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>

const LinkStatusPage: FC<ReduxType> = ({
  getLinkStatus,
  initialized,
  linkStatus,
  lastTxHash,
  createdAt,
  tokenAddress,
  escrowAddress,
  address,
  chainId,
  amount,
  name,
  loading,
  decimals
}) => {
  const history = useHistory()
  const {
    chain_id: urlChainId,
    transfer_id: transferId
  } = useParams<{
    chain_id: string,
    transfer_id: string
  }>()

  const query = useQuery()
  
  useEffect(() => {
    if (!initialized) {
      return
    }
    getLinkStatus(
      transferId,
      (location: string) => {
        const params = сreateQueryString({
          amount: '0',
          w: (query.get('w') as TWalletName) || 'metamask',
          theme: query.get('theme') || 'light',
        })
        history.push(`${location}?${params}`)
      }
    )
  }, [initialized])
  const humanReadableAmount = amount && decimals ? ethers.utils.formatUnits(amount, decimals) : '0'

  return <PageConnector
    urlChainId={urlChainId}
  >
    <Container>
      <PageHeader
        title='Details'
        backAction={() => {
          history.goBack()
        }}

      />
      <StatusContainer
        from={address}
        chainId={chainId as number}
        to={escrowAddress || ''}
        symbol={name}
        amount={String(humanReadableAmount)}
        status={linkStatus as TLinkStatus}
        txHash={lastTxHash || ''}
        created={String(new Date())}
      />
    </Container>
    
  </PageConnector>
}

export default connect(mapStateToProps, mapDispatcherToProps)(LinkStatusPage)
