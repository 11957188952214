import { FC, ReactElement, useEffect } from 'react'
import InitialScreen from './initial-screen'
import ChangeNetwork from './change-network'
import ClaimingFinished from './claiming-finished'
import ClaimingProcess from './claiming-process'
import AlreadyClaimed from './already-claimed'
import SetConnector from './set-connector'
import NoTokensLeft from './no-tokens-left'
import ErrorPage from './error'
import ErrorTransactionPage from './error-transaction'
import ErrorNoConnectionPage from './error-no-connection'
import { useQuery } from 'hooks'
import { TWalletName } from 'types'
import {
  WalletsListPage,
  PageHeader,
  DownloadAwait,
  WalletRedirectAwait
} from 'components/pages/common'
import ErrorServerFail from './error-server-fail'
import ErrorLinkExpired from './error-link-expired'
import ErrorAlreadyClaimed from './error-already-claimed'
import ErrorLinkNotFound from './error-link-not-found'
import ErrorLinkNoConnection from './error-link-no-connection'
import ErrorLink from './error-link'
import ChooseWallet from './choose-wallet'
import ShortCodeLoading from './short-code-loading'
import HighGasPrice from './high-gas-price'
import { Loader } from 'components/common'
import { TClaimLinkStep } from 'types'
import { RootState, IAppDispatch } from 'data/store'
import { connect } from 'react-redux'
import { Container } from './styled-components'
import { Dispatch } from 'redux'
import * as userAsyncActions from 'data/store/reducers/user/async-actions'
import * as linkAsyncActions from 'data/store/reducers/link/async-actions'
import * as linkActions from 'data/store/reducers/link/actions'
import { LinkActions } from 'data/store/reducers/link/types'
import { TokenActions } from 'data/store/reducers/token/types'
import { UserActions } from 'data/store/reducers/user/types'
import PageConnector from '../page-connector'
import { useChainId } from 'wagmi'
import { getHashVariables } from 'helpers'

const mapStateToProps = ({
  user: {
    address,
    provider,
    chainId,
    initialized: userInitialized
  },
  link: {
    step,
    recommendedWalletApp,
    initialized
  },
  token: {
    tokenType
  }
}: RootState) => ({
  address,
  step,
  provider,
  chainId,
  initialized,
  tokenType,
  userInitialized,
  recommendedWalletApp
})

const mapDispatcherToProps = (dispatch: Dispatch<LinkActions> & Dispatch<TokenActions> & Dispatch<UserActions> & IAppDispatch) => {
  return {
    getLinkData: (
      claimUrl: string
    ) => dispatch(linkAsyncActions.getLinkData(
      claimUrl
    )),
    setStep: (step: TClaimLinkStep) => dispatch(linkActions.setStep(step)),
    claimERC1155: (address: string) => dispatch(
      linkAsyncActions.claimERC1155(address, true)
    ),
    claimERC721: (address: string) => dispatch(
      linkAsyncActions.claimERC721(address, true)
    ),
    claimERC20: (address: string) => dispatch(
      linkAsyncActions.claimERC20(address, true)
    ),
    switchNetwork: (chain: number, callback: () => void) => dispatch(userAsyncActions.switchNetwork(
      chain,
      callback
    )),
    setRecommendedWalletApp: (walletApp: TWalletName | null) => dispatch(linkActions.setRecommendedWalletApp(
      walletApp
    )),
  }
}

// @ts-ignore
type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>

type TDefineStep = (
  step: TClaimLinkStep,
  setStep: (step: TClaimLinkStep) => void,
  recommendedWalletApp: TWalletName | null
) => ReactElement

const defineCurrentScreen: TDefineStep = (
  step,
  setStep,
  recommendedWalletApp
  // setAddressCallback
) => {
  switch (step) {
    case 'initial':
      return <InitialScreen />
    case 'change_network':
      return <ChangeNetwork />
    case 'claiming_process':
      return <ClaimingProcess />
    case 'claiming_finished':
      return <ClaimingFinished />
    case 'already_claimed':
      return <AlreadyClaimed />
    case 'set_connector':
      return <SetConnector />
    case 'no_tokens_left':
      return <NoTokensLeft />
    case 'error':
      return <ErrorPage />
    // case 'set_address':
    //   return <SetAddress
    //     onSubmit={setAddressCallback}
    //   />
    case 'error_transaction':
      return <ErrorTransactionPage />
    case 'error_no_connection':
      return <ErrorNoConnectionPage />
    case 'error_server_fail':
      return <ErrorServerFail />
    case 'link_expired':
      return <ErrorLinkExpired />
    case 'error_already_claimed':
      return <ErrorAlreadyClaimed />
    case 'choose_wallet':
      return <ChooseWallet />
    case 'wallets_list':
      return <WalletsListPage
        enableENS
        setStep={setStep}
        enableZerion
        recommendedWalletApp={recommendedWalletApp}
      />
    case 'gas_price_high':
      return <HighGasPrice />
    case 'error_link_not_found':
      return <ErrorLinkNotFound />
    case 'error_link_no_connection':
      return <ErrorLinkNoConnection />
    case 'error_link':
      return <ErrorLink />
    case 'short_code_loading':
      return <ShortCodeLoading />
    case 'download_await':
      return <DownloadAwait />
    case 'wallet_redirect_await':
      return <WalletRedirectAwait />
    default:
      return <Loader />
  }
}

const defineBackAction = (
  step: TClaimLinkStep,
  action: (prevoiusStep: TClaimLinkStep) => void
) => {
  switch (step) {
    case 'set_address':
    case 'download_await':
    case 'crossmint_connection':
      return () => action('wallets_list')
    case 'wallet_redirect_await':
      return () => action('wallets_list')
    case 'wallets_list':
      return () => action('choose_wallet')
    case 'choose_wallet':
      return () => action('set_connector')
    default:
      return null
  }
}

const defineHeader = (
  step: TClaimLinkStep,
  action: (prevStep: TClaimLinkStep) => void,
  chainId: number | null,
  switchNetwork: (chainId: number, callback: () => void) => void
) => {
  const backAction = defineBackAction(step, action)

  return <PageHeader
    backAction={backAction}
    chainId={chainId}
    switchNetwork={switchNetwork}
    switchNetworkCallback={() => {
      window.location.reload()
    }}

  />
}

const ClaimPage: FC<ReduxType> = ({
  step,
  getLinkData,
  setStep,
  tokenType,
  claimERC1155,
  claimERC20,
  claimERC721,
  provider,
  userInitialized,
  initialized,
  chainId,
  address,
  switchNetwork,
  recommendedWalletApp,
  setRecommendedWalletApp
}) => {
  const chain = useChainId()
  const connectorChainId = chain || null
  const providerConnectionUrl = provider ? provider.connection.url : null

  useEffect(() => {
    const hashVariables = getHashVariables()
    if (!hashVariables || !hashVariables.w) {
      return
    } else {
      setRecommendedWalletApp(hashVariables.w as TWalletName)
    }
  }, [])

  useEffect(() => {
    console.log('s')
    getLinkData(
      window.location.href
    )
  }, [])

  useEffect(() => {
    if (!userInitialized) { return }
    if (step === 'set_connector' || step === 'wallets_list') {
      setStep('initial')
    }
  }, [userInitialized, step])

  const queryParams = useQuery()
  const queryParamsChainId = queryParams && queryParams.get('c')
  const screen = defineCurrentScreen(
    step,
    setStep,
    recommendedWalletApp
    // setAddressCallback
  )
  
  return <PageConnector
    urlChainId={queryParamsChainId}
    type='secondary'
  >
    <Container>
      {defineHeader(
        step,
        setStep,
        chainId,
        switchNetwork
      )}
      {screen}
    </Container> 
  </PageConnector>
}

// @ts-ignore
export default connect(mapStateToProps, mapDispatcherToProps)(ClaimPage)
