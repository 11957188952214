
import { Dispatch } from 'redux'
import * as actionCreateLink from '../actions'
import { CreateLinkActions } from '../types'
import { TokenActions } from '../../token/types'
import { UserActions } from '../../user/types'
import { RootState, IAppDispatch } from 'data/store'
import { alertError } from 'helpers'

export default function updateTokenToSend(
  callback: () => void
) {

  // @ts-ignore
  return async (
    dispatch: Dispatch<CreateLinkActions> &
              Dispatch<TokenActions> &
              Dispatch<UserActions> &
              IAppDispatch,
    getState: () => RootState
  ) => {
    try {
      dispatch(actionCreateLink.setInitialized(false))
      dispatch(actionCreateLink.setStep('set_amount'))

      callback && callback()
    } catch (err) {
      alertError('Some error occured. Please check console for more info')
      console.error(err)
    }
  }
}
