
import { Dispatch } from 'redux'
import * as actionsLink from '../actions'
import * as actionsUser from '../../user/actions'
import * as actionsToken from '../../token/actions'
import * as asyncActionsToken from '../../token/async-actions'
import { LinkActions } from '../types'
import { TokenActions } from '../../token/types'
import { UserActions } from '../../user/types'
import { RootState, IAppDispatch } from 'data/store'
import { alertError, getHashVariables } from 'helpers'
import { TTokenType, TWalletName } from 'types'
import { BigNumber } from 'ethers'
import { LinkdropP2P } from 'linkdrop-p2p-sdk'
import randomBytes from 'randombytes'
import { ethers } from 'ethers'
import { plausibleApi } from 'data/api'
import { defineJSONRpcUrl } from 'helpers'
const { REACT_APP_ZUPLO_API_KEY, REACT_APP_INFURA_ID } = process.env

export default function getLinkData(
  claimUrl: string
) {
  // @ts-ignore
  return async (
    dispatch: Dispatch<LinkActions> &
              Dispatch<TokenActions> &
              Dispatch<UserActions> &
              IAppDispatch,
    getState: () => RootState
  ) => {
    dispatch(actionsLink.setLoading(true))

    try {

      const getRandomBytes = (length: number) => new Uint8Array(randomBytes(length)) 
      console.log({ page: 'get-link-data' })
      const sdk = new LinkdropP2P({
        apiKey: REACT_APP_ZUPLO_API_KEY as string,
        baseUrl: window.location.origin,
        getRandomBytes
      })

      dispatch(actionsUser.setSDK(sdk)) 

      const hashVariables = getHashVariables(claimUrl)

      if (hashVariables.w) {
        dispatch(actionsLink.setRecommendedWalletApp(hashVariables.w as TWalletName ))
      }

      const claimLink = await sdk.getClaimLink(claimUrl)
      console.log({ claimLink })
      if (claimLink) {
        plausibleApi.invokeEvent({
          eventName: 'claim_link_data_fetched',
          data: {
            transferId: claimLink.transferId
          }
        })
        const { amount, expiration, status, tokenType, token, chainId } = claimLink
        dispatch(actionsUser.setClaimLink(claimLink))
        dispatch(actionsToken.setAmount(BigNumber.from(amount))) 
        dispatch(actionsToken.setTokenType(tokenType as TTokenType))
        dispatch(actionsToken.setAddress(token))
        dispatch(actionsLink.setChainId(chainId))

        if (
          token &&
          chainId
        ) {
          const jsonRpcUrl = defineJSONRpcUrl({ chainId, infuraPk: REACT_APP_INFURA_ID as string })
          const provider = new ethers.providers.JsonRpcProvider(jsonRpcUrl)
          await asyncActionsToken.getTokenData(
            dispatch,
            getState,
            provider,
            chainId
          )
        }

        if (status  === 'redeemed') {
          dispatch(actionsLink.setIsClaimed(true))
          dispatch(actionsLink.setLoading(false))
          dispatch(actionsLink.setInitialized(true))
          plausibleApi.invokeEvent({
            eventName: 'claim_link_redeem_already_finished',
            data: {
              transferId: claimLink.transferId
            }
          })
          return dispatch(actionsLink.setStep('already_claimed'))

        }
        dispatch(actionsLink.setExpirationTime(String(expiration)))
        // dispatch(actionsToken.setAmount(String(amount)))
        // const tokenAddress = defineTokenAddressBySymbol(tokenSymbol, chainId)
        if (Number(expiration) < (+new Date() / 1000)) {
          dispatch(actionsLink.setInitialized(true))
          dispatch(actionsLink.setLoading(false))

          plausibleApi.invokeEvent({
            eventName: 'error',
            data: {
              err_name: 'link_expired'
            }
          })
          return dispatch(actionsLink.setStep('link_expired'))
        }

        dispatch(actionsLink.setStep('set_connector'))
        
      }
      
    } catch (
      error: any
    ) {
      console.log(error, error.statusCode)
    }
    dispatch(actionsLink.setLoading(false))

  }
}
