type TDefineNetworkByStablecoinAddress = (
  tokenAddress: string
) => number | null

const defineNetworkByStablecoinAddress: TDefineNetworkByStablecoinAddress = (
  tokenAddress
) => {
    switch (tokenAddress.toLowerCase()) {
      case '0xaf88d065e77c8cc2239327c5edb3a432268e5831':
        return 42161
      case '0x0b2c639c533813f4aa9d7837caf62653d097ff85':
        return 10
      case '0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e':
        return 43114
      case '0x1c7d4b196cb0c7b01d743fbc6116a902379c7238':
        return 11155111
      case '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913':
        return 8453
      case '0xf175520c52418dfe19c8098071a252da48cd1c19':
        return 84531
      case '0xcb444e90d8198415266c6a2724b7900fb12fc56e':
        return 100
      case '0x3c499c542cef5e3811e1192ce70d8cc03d5c3359':
      case '0x2791bca1f2de4661ed88a30c99a7a9449aa84174':
        return 137
      default:
        return null
    }
}

export default defineNetworkByStablecoinAddress