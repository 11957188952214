import { Dispatch } from 'redux'
import * as actionsDrop from '../actions'
import * as actionsToken from '../../token/actions'

// import getERC1155Data from './get-erc1155-token-data'
// import getERC721Data from './get-erc721-token-data'
import getERCToken20Data from './get-erc20-token-data'
import getNativeTokenData from './get-native-token-data'
import { RootState, IAppDispatch } from 'data/store'
import { TokenActions } from '../../token/types'
import { UserActions } from '../../user/types'

export default async function getTokenData(
  dispatch:  Dispatch<TokenActions> & Dispatch<UserActions> & IAppDispatch,
  getState: () => RootState,
  provider: any,
  chainId: number
) {
  const {
    token: {
      tokenType,
      address
    }
  } = getState()

  try {

    if (tokenType === 'ERC20' && address) {
      const { symbol, decimals, image } = await getERCToken20Data(provider, address, chainId)
      // @ts-ignore
      dispatch(actionsToken.setName(symbol))
      dispatch(actionsToken.setImage(image))
      dispatch(actionsToken.setDecimals(decimals))
      return
    }

    const { symbol, decimals, image } = getNativeTokenData(chainId)
    dispatch(actionsToken.setName(symbol))
    dispatch(actionsToken.setImage(image))
    dispatch(actionsToken.setDecimals(decimals))
  } catch (
    error: any
  ) {
    console.log(error, error.statusCode)
  }
}
