import { useEffect, useState } from 'react'
import {
  useAccount,
  useChainId
} from 'wagmi'

const useConnection = (
  initialized: boolean | null,
  initialize: any,
  reinitialize: any,
  signer: any,
  urlChainId?: string | null,
  urlTokenAddress?: string,
) => {
  const account = useAccount()
  
  const { address, isConnected: active, connector } = account
  const chain = useChainId()
  const connectorChainId = chain || null
  const [ start, setStart ] = useState<boolean>(false)
  useEffect(() => {
    setStart(true)
  }, [])

  useEffect(() => {
    if (!active) { return }
    if (!start) { return }
    if (!initialized) {
      initialize(
        connector,
        signer,
        chain,
        urlChainId,
        address
      )
    } else {
      if (address) {
        reinitialize(
          address,
          chain,
          connector,
          signer
        )
      }
    }
  }, [start, address, connector, active, urlTokenAddress, connectorChainId, signer])

  return {
    address, 
    connector,
    active
  }
}

export default useConnection
