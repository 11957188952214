import { createBrowserHistory } from 'history'
import { combineReducers, createStore, compose, applyMiddleware } from 'redux';
import thunkMiddleware, { ThunkDispatch, ThunkMiddleware } from "redux-thunk"
import { userReducer } from './reducers/user/reducer'
import { tokenReducer } from './reducers/token/reducer'
import { linkReducer } from './reducers/link/reducer'
import { historyReducer } from './reducers/history/reducer'
import { createLinkReducer } from './reducers/create-link/reducer'
import { TokenActions, TokenState } from './reducers/token/types'
import { LinkActions, LinkState } from './reducers/link/types'
import { UserActions, UserState } from './reducers/user/types'
import { CreateLinkActions, CreateLinkState } from './reducers/create-link/types'
import { HistoryActions, HistoryState } from './reducers/history/types'

export const history = createBrowserHistory()

// @ts-ignore
type TActions = TokenActions &
                LinkActions &
                UserActions &
                CreateLinkActions &
                HistoryActions

const reducers = combineReducers({
  user: userReducer,
  link: linkReducer,
  token: tokenReducer,
  createLink: createLinkReducer,
  history: historyReducer
})

type IAppState = ReturnType<typeof reducers>

export interface RootState {
  user: UserState,
  token: TokenState,
  link: LinkState,
  createLink: CreateLinkState,
  history: HistoryState
}

export type IAppDispatch = ThunkDispatch<IAppState, any, TActions>

const composeEnhancers =
    process.env.NODE_ENV === "development"
        ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
        : compose;

const store = createStore<RootState, any, any, any>(
  reducers,
  composeEnhancers(
    applyMiddleware<IAppDispatch, any>(
      thunkMiddleware as ThunkMiddleware<IAppState, TActions, any>,
    )
  )
)


export default store;